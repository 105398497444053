import { DidYouMeanTemplate } from "./templates/didYouMeanTemplate";
import { PaginationTemplate } from "./templates/paginationTemplate";
import { SearchResultTemplate } from "./templates/searchResultTemplate";
/**
 * This class is responsible for rendering the individual elements of a search result page.
 */
var PageRenderer = /** @class */ (function () {
    /**
     * @param page The corresponding page whose content is to be rendered.
     */
    function PageRenderer(page) {
        this.page = page;
        this._didYouMeanTemplate = new DidYouMeanTemplate();
        this._paginationTemplate = new PaginationTemplate();
        this._searchResultTemplate = new SearchResultTemplate();
    }
    Object.defineProperty(PageRenderer.prototype, "didYouMeanTemplate", {
        /**
         * The 'Did you mean' template that is used to render alternative search suggestions.
         */
        get: function () {
            return this._didYouMeanTemplate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageRenderer.prototype, "paginationTemplate", {
        /**
         * The pagination template that is used to render a single pagination element.
         */
        get: function () {
            return this._paginationTemplate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageRenderer.prototype, "searchResultTemplate", {
        /**
         * The search result template that is used to render the search results.
         */
        get: function () {
            return this._searchResultTemplate;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Renders a next button (if a next page exists) using the [[paginationTemplate]].
     *
     * @param label The label used to describe the button.
     */
    PageRenderer.prototype.renderNextButton = function (label) {
        if (label === void 0) { label = "Next"; }
        if (!this.page.isStaticPagination(this.page.paginationParams)) {
            throw Error("Static pagination parameters are expected.");
        }
        if (this.page.hasNext()) {
            var staticPaginationParams = this.page.paginationParams;
            var nextPageNumber = staticPaginationParams.pageNumber + 1;
            return this.renderPaginationElement(this._paginationTemplate.cssClasses.nextButton, nextPageNumber, this._paginationTemplate.renderNext, label);
        }
    };
    /**
     * Renders a next button (if a next page exists) using the [[paginationTemplate]] and appends it to the element.
     *
     * @param element The HTML element to which the button is attached.
     * @param label The label used to describe the button.
     */
    PageRenderer.prototype.renderNextButtonToHTMLElement = function (element, label) {
        if (label === void 0) { label = "Next"; }
        var nextButton = this.renderNextButton(label);
        if (nextButton !== undefined) {
            element.append(nextButton);
        }
    };
    /**
     * Renders a previous button (if a previous page exists) using the [[paginationTemplate]].
     *
     * @param label The label used to describe the button.
     */
    PageRenderer.prototype.renderPrevButton = function (label) {
        if (label === void 0) { label = "Prev"; }
        if (!this.page.isStaticPagination(this.page.paginationParams)) {
            throw Error("Static pagination parameters are expected.");
        }
        if (this.page.hasPrev()) {
            var staticPaginationParams = this.page.paginationParams;
            var prevPageNumber = staticPaginationParams.pageNumber - 1;
            return this.renderPaginationElement(this._paginationTemplate.cssClasses.prevButton, prevPageNumber, this._paginationTemplate.renderPrev, label);
        }
    };
    /**
     * Renders a previous button (if a previous page exists) using the [[paginationTemplate]] and appends it to the
     * element.
     *
     * @param element The HTML element to which the button is attached.
     * @param label The label used to describe the button.
     */
    PageRenderer.prototype.renderPrevButtonToHTMLElement = function (element, label) {
        if (label === void 0) { label = "Prev"; }
        var prevButton = this.renderPrevButton(label);
        if (prevButton !== undefined) {
            element.append(prevButton);
        }
    };
    /**
     * Renders the buttons that are used to navigate to a specific page using the [[paginationTemplate]].
     *
     * @param amount The number of page buttons that are rendered.
     */
    PageRenderer.prototype.renderPageButtons = function (amount) {
        if (!this.page.isStaticPagination(this.page.paginationParams)) {
            throw Error("Static pagination parameters are expected.");
        }
        var staticPaginationParams = this.page.paginationParams;
        var numberOfPages = Math.ceil(this.page.numberOfResults / staticPaginationParams.pageSize);
        var firstPageNumber = this.calculateFirstPageNumber(amount, numberOfPages);
        var renderedPages = [];
        for (var i = 0; i < amount; i++) {
            var pageNumber = firstPageNumber + i;
            var page = this.renderPaginationElement(this._paginationTemplate.cssClasses.pageButton, pageNumber, this._paginationTemplate.renderPage);
            renderedPages.push(page);
            if (firstPageNumber + i + 1 === numberOfPages) {
                break;
            }
        }
        var currentPageNumber = staticPaginationParams.pageNumber;
        this.highlightCurrentPage(renderedPages, currentPageNumber);
        return renderedPages;
    };
    PageRenderer.prototype.calculateFirstPageNumber = function (amount, numberOfPages) {
        var staticPaginationParams = this.page.paginationParams;
        var currentPageNumber = staticPaginationParams.pageNumber;
        var firstPageNumber = currentPageNumber - Math.floor(amount / 2);
        if (Math.ceil(amount / 2) > numberOfPages - currentPageNumber) {
            firstPageNumber -= Math.ceil(amount / 2) - (numberOfPages - currentPageNumber);
        }
        return Math.max(0, firstPageNumber);
    };
    PageRenderer.prototype.highlightCurrentPage = function (pages, currentPageNumber) {
        var _this = this;
        pages
            .filter(function (page) { return page.getAttribute("haupia-page-value") === currentPageNumber.toString(); })
            .forEach(function (page) {
            var _a;
            return (_a = page.classList).add.apply(_a, _this._paginationTemplate.cssClasses.currentPage);
        });
    };
    /**
     * Renders the buttons that are used to navigate to a specific page using the [[paginationTemplate]] and appends it
     * to the element.
     *
     * @param element The HTML element to which the button is attached.
     * @param amount The number of page buttons that are rendered.
     */
    PageRenderer.prototype.renderPageButtonsToHTMLElement = function (element, amount) {
        element.append.apply(element, this.renderPageButtons(amount));
    };
    /**
     * Renders the complete navigation (previous button, page buttons and next button) using the [[paginationTemplate]].
     *
     * @param amount The number of page buttons that are rendered.
     */
    PageRenderer.prototype.renderPagination = function (amount) {
        var _a;
        var wrapperElement = document.createElement("ul");
        this.renderPrevButtonToHTMLElement(wrapperElement);
        this.renderPageButtonsToHTMLElement(wrapperElement, amount);
        this.renderNextButtonToHTMLElement(wrapperElement);
        (_a = wrapperElement.classList).add.apply(_a, this._paginationTemplate.cssClasses.pagination);
        return wrapperElement;
    };
    /**
     * Renders the complete navigation (previous button, page buttons and next button) using the [[paginationTemplate]]
     * and appends them to the element.
     *
     * @param element The HTML element to which the button is attached.
     * @param amount The number of page buttons that are rendered.
     */
    PageRenderer.prototype.renderPaginationToHTMLElement = function (element, amount) {
        element.append(this.renderPagination(amount));
    };
    PageRenderer.prototype.renderPaginationElement = function (cssClasses, value, renderFunction, label) {
        var _a;
        var element = document.createElement("li");
        (_a = element.classList).add.apply(_a, cssClasses);
        element.setAttribute("haupia-page-value", value.toString());
        element.innerHTML = renderFunction(label || (value + 1).toString());
        return element;
    };
    /**
     * Renders all search results into a div-element using the [[searchResultTemplate]].
     *
     * @returns An element for you to place wherever you want in your DOM Tree.
     */
    PageRenderer.prototype.renderSearchResults = function () {
        var _a;
        var _this = this;
        var renderedResults = this.page.searchResults.map(function (result) {
            var _a;
            var resultBox = document.createElement("div");
            resultBox.innerHTML = result.render(_this._searchResultTemplate.templateFunction);
            (_a = resultBox.classList).add.apply(_a, _this._searchResultTemplate.cssClasses.element);
            return resultBox;
        });
        var wrapperElement = document.createElement("div");
        (_a = wrapperElement.classList).add.apply(_a, this._searchResultTemplate.cssClasses.wrapper);
        wrapperElement.append.apply(wrapperElement, renderedResults);
        return wrapperElement;
    };
    /**
     * Renders all search results into a div-element using the [[searchResultTemplate]] and appends it to the
     * element.
     *
     * @param element The html div element to attach the search results to.
     */
    PageRenderer.prototype.renderSearchResultsToHTMLElement = function (element) {
        element.appendChild(this.renderSearchResults());
    };
    /**
     * Renders all 'Did you mean' suggestions into a div-element using the provided [[didYouMeanTemplate]].
     *
     * @throws an error if there are no 'Did you mean' search suggestions.
     *
     * @returns An element for you to place wherever you want in your DOM Tree.
     */
    PageRenderer.prototype.renderDidYouMean = function () {
        var _a;
        var _this = this;
        if (this.page.didYouMean.length === 0) {
            throw Error("There are no search query suggestions.");
        }
        var renderedSugestions = this.page.didYouMean.map(function (value) {
            var _a;
            var _b, _c;
            var alibiWrapper = document.createElement("div");
            alibiWrapper.innerHTML = _this._didYouMeanTemplate.renderDidYouMean(value);
            var element = alibiWrapper.firstElementChild;
            (_b = element) === null || _b === void 0 ? void 0 : (_a = _b.classList).add.apply(_a, _this._didYouMeanTemplate.cssClasses.element);
            return (_c = element) === null || _c === void 0 ? void 0 : _c.outerHTML;
        });
        var wrapperElement = document.createElement("div");
        (_a = wrapperElement.classList).add.apply(_a, this._didYouMeanTemplate.cssClasses.wrapper);
        wrapperElement.insertAdjacentHTML("afterbegin", renderedSugestions.join(""));
        return wrapperElement;
    };
    /**
     * Renders all 'Did you mean' suggestions into a div-element using the provided [[didYouMeanTemplate]] and appends
     * it to the element.
     *
     * @throws an error if there are no 'Did you mean' search suggestions.
     *
     * @param element The html div element to attach the suggestions to.
     */
    PageRenderer.prototype.renderDidYouMeanToHTMLElement = function (element) {
        element.appendChild(this.renderDidYouMean());
    };
    return PageRenderer;
}());
export default PageRenderer;

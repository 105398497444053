var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Page } from "./page";
import { AutocompleteWidget } from "./autocompleteWidget";
import { API_ENDPOINT } from "../defaults";
import { FetchClient } from "./fetchClient";
import PageRenderer from "./pageRenderer";
/**
 * Wrapper for the haupia prepared search API. Main class and entrypoint for this library.
 */
var haupia = /** @class */ (function () {
    /**
     * @param host The hostname including protocol (eg.: https://my.haupia.backend).
     * @param preparedSearch The name of the prepared search to address.
     * @param options The additional options to override the default parameters of the API endpoint, the render function
     *  and autocomplete function.
     */
    function haupia(host, preparedSearch, options) {
        var _a, _b, _c, _d;
        this.host = host;
        this.preparedSearch = preparedSearch;
        this.options = options;
        this.apiEndpoint = ((_a = options) === null || _a === void 0 ? void 0 : _a.apiEndpoint) || API_ENDPOINT;
        var baseURL = this.host + this.apiEndpoint + ("/" + this.preparedSearch + "/");
        this._searchClient = new FetchClient(baseURL, { customParams: (_b = options) === null || _b === void 0 ? void 0 : _b.customParams });
        var autocompleteOptions = (_c = options) === null || _c === void 0 ? void 0 : _c.autocompleteOptions;
        var autocompleteClient = new FetchClient(baseURL, { autocomplete: true, customParams: (_d = options) === null || _d === void 0 ? void 0 : _d.customParams });
        this.autocompleteWidget = new AutocompleteWidget(autocompleteClient, autocompleteOptions);
    }
    Object.defineProperty(haupia.prototype, "searchClient", {
        get: function () {
            return this._searchClient;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Sends a search request with the given query term.
     *
     * @param queryTerm The search term to use in the api request.
     * @param customParams One time use only parameters to append to this request
     * @returns The [[Page]] containing all the search results for that page.
     */
    haupia.prototype.search = function (queryTerm) {
        var customParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            customParams[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var params, responseData, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = (_a = this.searchClient.parameterList
                            .addOrUpdateSearchTerm(queryTerm)).add.apply(_a, customParams || []);
                        this.searchClient.parameterList = params;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.searchClient.fetch()];
                    case 2:
                        responseData = (_b.sent());
                        this.searchClient.parameterList = params.delete.apply(params, customParams);
                        return [2 /*return*/, new Page(this.searchClient, responseData)];
                    case 3:
                        error_1 = _b.sent();
                        this.searchClient.parameterList = params.delete.apply(params, customParams);
                        throw new Error(error_1);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    haupia.prototype.mapPages = function (responseData, groupField, queryTerm) {
        var groupByField = responseData.groups.find(function (group) { return group.name === groupField; });
        if (groupByField === undefined) {
            throw Error("Searched group not found!");
        }
        var mappedPages = new Map();
        var params = this.searchClient.parameterList.addOrUpdateSearchTerm(queryTerm);
        this.searchClient.parameterList = params;
        for (var _i = 0, _a = groupByField.groups; _i < _a.length; _i++) {
            var group = _a[_i];
            var response = JSON.parse(JSON.stringify(responseData));
            response.results = group.results;
            response.numRows = group.numRows;
            var page = new Page(this.searchClient, response);
            mappedPages.set(group.value, page);
        }
        return mappedPages;
    };
    /**
     * Sends a search request with the query term and the grouping options. For the method to work, the following groovy
     * script must be added to the prepared search.
     *
     * <pre><code>
     * def grouping = "off"
     * grouping = parameter.get("grouping")?.get(0)
     * def groupField = parameter.get("groupField")?.get(0)
     * def groupLimit = parameter.get("groupLimit")?.get(0)
     *
     * if (grouping?.equals("on")) {
     *     solrQuery.set("group", true)
     *     solrQuery.set("group.field", groupField)
     *     solrQuery.set("group.limit", groupLimit)
     * }
     * </code></pre>
     *
     * @param queryTerm The search term to use in the api request.
     * @param groupField The name of the field by which to group results.
     * @param groupLimit The number of results to return for each group.
     * @param customParams
     * @returns A map consisting of the group value and the correspondig [[Page]] that contains the search results.
     */
    haupia.prototype.searchWithGroup = function (queryTerm, groupField, groupLimit) {
        var customParams = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            customParams[_i - 3] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var queryParams, params, responseData, error_2;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParams = [
                            { query: queryTerm },
                            { grouping: "on" },
                            { groupField: groupField },
                            { groupLimit: groupLimit.toString() },
                        ];
                        params = (_a = (_b = this.searchClient.parameterList).addOrUpdate.apply(_b, queryParams)).add.apply(_a, customParams || []);
                        this.searchClient.parameterList = params;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.searchClient.fetch()];
                    case 2:
                        responseData = (_c.sent());
                        this.searchClient.parameterList = params.delete.apply(params, customParams);
                        return [2 /*return*/, this.mapPages(responseData, groupField, queryTerm)];
                    case 3:
                        error_2 = _c.sent();
                        this.searchClient.parameterList = params.delete.apply(params, customParams);
                        throw new Error(error_2);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Fetches the autocomplete suggestions matching the given prefix from the backend. The resulting array always has
     * the length <strong>5</strong> and is sorted by relevance.
     *
     * @param prefix What the user typed so far.
     */
    haupia.prototype.fetchAutocompleteList = function (prefix, options) {
        return this.autocompleteWidget.getAutocompleteList(prefix, options);
    };
    // TODO: The CSS-classes should be configurable. The API comment should then be moved to the newly created constant.
    /**
     * This function attaches an autocomplete widget to the given input element. The css styling classes are:
     *
     * <code>.hp-autocomplete-widget</code> will be attached to the div-container displaying the list of autocomplete
     *  suggestions.<br>
     * <code>.hp-autocomplete-active</code> will be attached to the cursor for the actively selected autocomplete widget
     *  .<br>
     * <code>.hp-autocomplete-item</code> will be attached to a single autocomplete suggestion.<br>
     * <code>#inputElement.id</code> will be attached to the autocomplete-list.
     *
     * @param inputElement The input element to attach this widget to
     * @param renderTarget Optional. A div to attach the autocomplete widget to. If this parameter is left empty,
     * the widget will be attached to the parent of the input element.
     */
    haupia.prototype.attachAutocompleteWidget = function (inputElement, renderTarget) {
        this.autocompleteWidget.initializeAndAttach(inputElement, renderTarget);
    };
    /**
     * Returns a page renderer object that provides functions to render pagination and search results
     * @param page the page to berendered
     */
    haupia.prototype.getPageRenderer = function (page) {
        return new PageRenderer(page);
    };
    return haupia;
}());
export { haupia };

import { CSS_SEARCH_RESULT_WRAPPER, CSS_SEARCH_RESULT_ELEMENT, SEARCH_RESULT_FUNCTION } from "../../defaults";
/**
 * Represents the users desired shape of the [[SearchResult]] data.
 */
var SearchResultTemplate = /** @class */ (function () {
    function SearchResultTemplate() {
        this.cssClasses = this.initDefaultClasses();
        this.templateFunction = SEARCH_RESULT_FUNCTION;
    }
    SearchResultTemplate.prototype.initDefaultClasses = function () {
        return {
            wrapper: [CSS_SEARCH_RESULT_WRAPPER],
            element: [CSS_SEARCH_RESULT_ELEMENT],
        };
    };
    return SearchResultTemplate;
}());
export { SearchResultTemplate };

import { DID_YOU_MEAN_FUNCTION, CSS_DID_YOU_MEAN_ELEMENT, CSS_DID_YOU_MEAN_WRAPPER } from "../../defaults";
/**
 * Represents the desired shape of the 'Did you mean' search query suggestions.
 */
var DidYouMeanTemplate = /** @class */ (function () {
    function DidYouMeanTemplate() {
        this.cssClasses = this.initDefaultClasses();
        this.renderDidYouMean = DID_YOU_MEAN_FUNCTION;
    }
    DidYouMeanTemplate.prototype.initDefaultClasses = function () {
        return {
            wrapper: [CSS_DID_YOU_MEAN_WRAPPER],
            element: [CSS_DID_YOU_MEAN_ELEMENT],
        };
    };
    return DidYouMeanTemplate;
}());
export { DidYouMeanTemplate };

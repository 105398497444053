var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Page } from "./page";
var Facet = /** @class */ (function () {
    /**
     * @param searchClient The fetch client to send the HTTP requests.
     * @param data The possible values to filter this searchTerm by.
     * @param config The facets configured in the prepared search.
     * @param haupiaOptions
     */
    function Facet(searchClient, data, config) {
        var _this = this;
        this.data = data;
        this.config = config;
        this._filterClient = searchClient;
        this._name = config.baseName;
        this._type = data.name.split("_").pop();
        this._counts = this.data.counts.map(function (counts) {
            counts.filterQuery = _this.getFilterParamName();
            return counts;
        });
    }
    Object.defineProperty(Facet.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Facet.prototype, "counts", {
        /**
         * @returns An array containing the possible values for this facet that can be used in the filter function as well as the number of
         * search results that would be returned if you filtered by value.
         */
        get: function () {
            return this._counts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Facet.prototype, "filterClient", {
        get: function () {
            return this._filterClient;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Facet.prototype, "displayName", {
        get: function () {
            return this._displayName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Facet.prototype, "selectedValues", {
        get: function () {
            return this._selectedValues;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Facet.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Facet.prototype.getQueryParam = function (value) {
        var param = {};
        param[this.getFilterParamName()] = "\"" + value + "\"";
        return param;
    };
    Facet.prototype.getFilterParamName = function () {
        return "facet.filter." + this.config.baseName;
    };
    Facet.prototype.getSelectedParameters = function () {
        var _this = this;
        var _a;
        return ((_a = this.selectedValues) === null || _a === void 0 ? void 0 : _a.map(function (value) { return _this.getQueryParam(value); })) || [];
    };
    Facet.prototype.isValueAllowed = function (value) {
        var allowedValues = this.counts.map(function (counts) { return counts.value; });
        return allowedValues.includes(value);
    };
    Facet.prototype.setSelectedValues = function () {
        var values = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            values[_i] = arguments[_i];
        }
        this._selectedValues = values;
        return this;
    };
    Facet.prototype.setDisplayName = function (name) {
        this._displayName = name;
        return this;
    };
    /**
     * Filters the search results for this search term using the facette values passed to this function.
     * @param filterValues The facet values to be filtered.
     * @throws When selected values are not allowed or multiple selections are not allowed.
     */
    Facet.prototype.filter = function () {
        var filterValues = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            filterValues[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var filterParams, clientParams;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                if (!this.config.multiSelect && filterValues.length > 1) {
                    throw new Error("This is not a multi value facet. You can only pass one value to filter().");
                }
                filterValues.forEach(function (value) {
                    if (_this.type !== typeof value) {
                        throw new Error("The facet " + _this.config.baseName + " is of type " + _this.type + ", but the value that was passed (" + value + ") is of type " + typeof value);
                    }
                    if (!_this.isValueAllowed(value)) {
                        throw new Error("The passed value " + value + " is not allowed in this facette");
                    }
                });
                filterParams = filterValues.map(function (value) { return _this.getQueryParam(value); });
                clientParams = (_a = this.filterClient.parameterList.deleteAll(this.getFilterParamName())).add.apply(_a, filterParams);
                this.filterClient.parameterList = clientParams.updateFirst({ haupia_start: "0" }).updateFirst({ haupia_pageNumber: "0" });
                return [2 /*return*/, this.search()];
            });
        });
    };
    Facet.prototype.search = function () {
        return __awaiter(this, void 0, void 0, function () {
            var json, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.filterClient.fetch()];
                    case 1:
                        json = (_a.sent());
                        return [2 /*return*/, new Page(this.filterClient, json)];
                    case 2:
                        error_1 = _a.sent();
                        throw new Error(error_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return Facet;
}());
export { Facet };

/**
 * The default API endpoint to which auto-complete and search queries are sent.
 */
export var API_ENDPOINT = "/v1/prepared_search";
/**
 * The default function to render a single pagination element. *The default algorithm is:*
 * ```typescript
 * (value: string): string => {
 *     return value
 * }
 * ```
 *
 * @param value The page value. The first page corresponds to the value 0.
 * @returns The given value without modification.
 */
export var PAGINATION_FUNCTION = function (value) {
    return value;
};
/**
 * The default function to render a single search result. *The default algorithm is:*
 * ```typescript
 * (data: Result, highlights: Highlighting): string => {
 *     return `<a href="${data.link}">
 *               <h1>${data.title}</h1>
 *               <p>${highlights.content}</p>
 *             </a>`
 * }
 * ```
 *
 * @param data The search result information like link or title.
 * @param highlights The content to be displayed on the search result page.
 */
export var SEARCH_RESULT_FUNCTION = function (data, highlights) {
    return "<a href=\"" + data.link + "\"><h1>" + data.title + "</h1><p>" + highlights.content + "</p></a>";
};
/**
 * The default function to render a single 'Did you mean' suggestion. *The default algorithm is:*
 * ```typescript
 * (value: string): string => {
 *     return value
 * }
 * ```
 *
 * @param value the suggestion.
 */
export var DID_YOU_MEAN_FUNCTION = function (value) {
    return "<span>" + value + "</span>";
};
/**
 * Default CSS class for the pagination wrapper HTML element.
 */
export var CSS_PAGINATION = "haupia-pagination";
/**
 * Default CSS class for the pagination next button.
 */
export var CSS_NEXT_BUTTON = "haupia-pagination-next";
/**
 * Default CSS class for the pagination prev button.
 */
export var CSS_PREV_BUTTON = "haupia-pagination-prev";
/**
 * Default CSS class for the pagination page buttons.
 */
export var CSS_PAGE_BUTTON = "haupia-pagination-page";
/**
 * Default CSS class for the pagination current page button.
 */
export var CSS_CURRENT_PAGE = "haupia-pagination-current";
/**
 * Default CSS class for the search result wrapper HTML element.
 */
export var CSS_SEARCH_RESULT_WRAPPER = "haupia-search-results";
/**
 * Default CSS class for a single search result inside the wrapper.
 */
export var CSS_SEARCH_RESULT_ELEMENT = "haupia-search-result";
/**
 * Default CSS class for the single 'Did you mean' wrapper.
 */
export var CSS_DID_YOU_MEAN_WRAPPER = "haupia-did-you-mean-wrapper";
/**
 * Default CSS class for a single 'Did you mean' suggestion.
 */
export var CSS_DID_YOU_MEAN_ELEMENT = "haupia-did-you-mean-element";

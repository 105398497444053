var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SearchResult } from "./searchResult";
import { Facet } from "./facet";
import { URLParamList } from "./urlParamList";
/**
 * This class represents a search engine results page (SERP).
 */
var Page = /** @class */ (function () {
    /**
     * The constructor gets called after [[haupia]] receives search results from the backend.
     *
     * @param fetchClient The [[FetchClient]] used to send API calls against the prepared search.
     * @param responseData The response JSON that may or may not have been filtered.
     * @param options Currently only used to get the template function
     */
    function Page(fetchClient, responseData) {
        var _this = this;
        this.responseData = responseData;
        this._fetchClient = fetchClient;
        this.responseData = responseData;
        this.searchResults = responseData.results.map(function (result) {
            var highlighting = _this.responseData.highlighting;
            return new SearchResult(result, highlighting);
        });
        this.paginationParams = this.extractPaginationParams(this.responseData);
        this.facets = this.hasFacets(responseData) ? this.extractFacets(responseData, fetchClient) : [];
    }
    Object.defineProperty(Page.prototype, "numberOfResults", {
        /** The total number of search results matching the search term. */
        get: function () {
            return this.responseData.numRows;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "didYouMean", {
        /** Returns alternative search queries if the [[numberOfResults]] value is beneath the threshold configured at the
         *  prepared search in the cockpit. */
        get: function () {
            return this.responseData.didYouMean;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "fetchClient", {
        get: function () {
            return this._fetchClient;
        },
        enumerable: true,
        configurable: true
    });
    Page.prototype.hasFacets = function (responseData) {
        return responseData.facets.length > 0 && responseData.facetConfigs.length > 0;
    };
    Page.prototype.extractFacets = function (responseData, searchClient) {
        return responseData.facets.map(function (responseFacet, index) {
            var facetBaseName = responseData.facetConfigs[index].baseName;
            var previouslyFilteredFacetKey = Object.keys(responseData.requestParameters).find(function (key) {
                return key.includes(facetBaseName);
            });
            var facet = new Facet(searchClient, responseFacet, responseData.facetConfigs[index]);
            if (previouslyFilteredFacetKey) {
                var selectedValues = responseData.requestParameters[previouslyFilteredFacetKey].map(function (value) {
                    return value.replace(/"/g, "");
                });
                var newFacet = facet.setSelectedValues.apply(facet, selectedValues);
                return newFacet;
            }
            return facet;
        });
    };
    Page.prototype.extractPaginationParams = function (responseData) {
        var requestParameters = responseData.requestParameters;
        var paginationParams;
        if (this.hasDynamicPaginationParams(requestParameters)) {
            paginationParams = this.extractDynamicPaginationParams(requestParameters);
        }
        else if (this.hasStaticPaginationParams(requestParameters)) {
            paginationParams = this.extractStaticPaginationParams(requestParameters);
        }
        else {
            paginationParams = this.setDefaultStaticPaginationParams(responseData.rows);
        }
        return paginationParams;
    };
    Page.prototype.hasDynamicPaginationParams = function (requestParameters) {
        var hasRows = Object.prototype.hasOwnProperty.call(requestParameters, "haupia_rows");
        var hasStart = Object.prototype.hasOwnProperty.call(requestParameters, "haupia_start");
        return hasRows && hasStart;
    };
    Page.prototype.extractDynamicPaginationParams = function (requestParameters) {
        var rows = Number(requestParameters.haupia_rows);
        var start = Number(requestParameters.haupia_start);
        if (isNaN(rows) || isNaN(start)) {
            throw Error("Invalid pagination parameters.");
        }
        var params = {
            rows: rows,
            start: start,
        };
        return params;
    };
    Page.prototype.hasStaticPaginationParams = function (requestParameters) {
        var hasPageNumber = Object.prototype.hasOwnProperty.call(requestParameters, "haupia_pageNumber");
        var hasPageSize = Object.prototype.hasOwnProperty.call(requestParameters, "haupia_pageSize");
        return hasPageNumber && hasPageSize;
    };
    Page.prototype.extractStaticPaginationParams = function (requestParameters) {
        var pageNumber = Number(requestParameters.haupia_pageNumber);
        var pageSize = Number(requestParameters.haupia_pageSize);
        if (isNaN(pageNumber) || isNaN(pageSize)) {
            throw Error("Invalid pagination parameters.");
        }
        var params = {
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        return params;
    };
    Page.prototype.setDefaultStaticPaginationParams = function (rows) {
        var params = {
            pageNumber: 0,
            pageSize: rows,
        };
        return params;
    };
    /**
     * @returns The total number of pages.
     */
    Page.prototype.getNumberOfPages = function () {
        if (!this.isStaticPagination(this.paginationParams)) {
            throw Error("Invalid pagination parameters defined. Static pagination parameters are required.");
        }
        return Math.ceil(this.responseData.numRows / this.paginationParams.pageSize);
    };
    /**
     * Returns the facet matching the given name if it exists on this prepared search.
     * @param name The name of the facet as it is displayed in the SmartSearch cockpit under Prepared Search > Facet
     */
    Page.prototype.getFacet = function (name) {
        var _a;
        return ((_a = this.facets) === null || _a === void 0 ? void 0 : _a.find(function (facet) { return facet.name === name; })) || null;
    };
    /**
     * This function doesn't work with [[DynamicPaginationParams]].
     *
     * @returns The first page with the defined pagination options.
     */
    Page.prototype.getFirstPage = function () {
        return this.getPage(0);
    };
    /**
     * This function doesn't work with [[DynamicPaginationParams]].
     *
     * @returns The last page with the defined pagination options.
     */
    Page.prototype.getLastPage = function () {
        var lastPage = this.getNumberOfPages();
        return this.getPage(lastPage);
    };
    /**
     * This function doesn't work with [[DynamicPaginationParams]].
     *
     * @param pageNumber
     * @returns The specified page with the defined pagination options.
     */
    Page.prototype.getPage = function (pageNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var newPaginationParams;
            return __generator(this, function (_a) {
                if (!this.isStaticPagination(this.paginationParams)) {
                    throw Error("Invalid pagination parameters defined. Static pagination parameters are required.");
                }
                newPaginationParams = this.paginationParams;
                newPaginationParams.pageNumber = pageNumber;
                if (!this.hasPage(pageNumber)) {
                    throw Error("There is no page number: " + pageNumber);
                }
                return [2 /*return*/, this.search(newPaginationParams)];
            });
        });
    };
    /**
     * Checks whether the page with the given page number exists.
     *
     * This function is used internally by the [[getPage]] function.
     *
     * @param pageNumber
     */
    Page.prototype.hasPage = function (pageNumber) {
        var staticPaginationParams = this.paginationParams;
        var numberOfTotalPages = Math.ceil(this.responseData.numRows / staticPaginationParams.pageSize);
        return numberOfTotalPages > pageNumber && pageNumber >= 0;
    };
    /**
     * Checks whether the [[next]] function would send a valid request.
     *
     * This function is used internally by the [[next]] function.
     */
    Page.prototype.hasNext = function () {
        var hasNext = false;
        if (this.isDynamicPagination(this.paginationParams)) {
            if (this.paginationParams.start + this.paginationParams.rows < this.responseData.numRows) {
                hasNext = true;
            }
        }
        else if (this.isStaticPagination(this.paginationParams)) {
            if (this.paginationParams.pageSize * (this.paginationParams.pageNumber + 1) < this.responseData.numRows) {
                hasNext = true;
            }
        }
        return hasNext;
    };
    /**
     * Checks whether the [[prev]] function would send a valid request.
     *
     * This function is used internally by the [[prev]] function.
     */
    Page.prototype.hasPrev = function () {
        var hasPrevious = false;
        if (this.isStaticPagination(this.paginationParams)) {
            if (this.paginationParams.pageNumber > 0) {
                hasPrevious = true;
            }
        }
        return hasPrevious;
    };
    /**
     * The API response contains the next matches based on the previously set pagination parameters.
     *
     * @returns The [[Page]] containing all the search results for that page.
     */
    Page.prototype.next = function () {
        return __awaiter(this, void 0, void 0, function () {
            var nextPaginationParams;
            return __generator(this, function (_a) {
                if (!this.hasNext()) {
                    throw Error("There is no next page.");
                }
                if (this.isDynamicPagination(this.paginationParams)) {
                    nextPaginationParams = __assign({}, this.paginationParams);
                    nextPaginationParams.start += this.paginationParams.rows;
                }
                else if (this.isStaticPagination(this.paginationParams)) {
                    nextPaginationParams = this.paginationParams;
                    nextPaginationParams.pageNumber++;
                }
                return [2 /*return*/, this.search(nextPaginationParams)];
            });
        });
    };
    /**
     * The API response contains the previous matches based on the previously set pagination parameters.
     *
     * @returns The [[Page]] containing all the search results for that page.
     */
    Page.prototype.prev = function () {
        return __awaiter(this, void 0, void 0, function () {
            var prevPaginationParams;
            return __generator(this, function (_a) {
                if (!this.hasPrev()) {
                    throw Error("There is no previous page.");
                }
                if (this.isStaticPagination(this.paginationParams)) {
                    prevPaginationParams = this.paginationParams;
                    prevPaginationParams.pageNumber--;
                }
                return [2 /*return*/, this.search(prevPaginationParams)];
            });
        });
    };
    Page.prototype.addOrUpdateFacetParams = function (paramList) {
        var returnlist = new URLParamList(paramList.list);
        if (this.facets) {
            this.facets.forEach(function (facet) {
                var _a;
                var selectedParams = facet.getSelectedParameters();
                returnlist = (_a = returnlist.deleteAll(facet.getFilterParamName())).add.apply(_a, selectedParams);
            });
        }
        return returnlist;
    };
    Page.prototype.search = function (paginationParams) {
        return __awaiter(this, void 0, void 0, function () {
            var fetchParams, params, json, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        fetchParams = this.buildFetchParams(paginationParams);
                        params = (_a = this.fetchClient.parameterList).addOrUpdate.apply(_a, fetchParams);
                        this.fetchClient.parameterList = this.addOrUpdateFacetParams(params);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fetchClient.fetch()];
                    case 2:
                        json = (_b.sent());
                        return [2 /*return*/, new Page(this.fetchClient, json)];
                    case 3:
                        error_1 = _b.sent();
                        throw new Error(error_1);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Page.prototype.buildFetchParams = function (paginationParams) {
        var fetchParams = [];
        if (paginationParams !== undefined) {
            var transformedQueryParams = this.transformPaginationParamsToFetchParams(paginationParams);
            fetchParams = fetchParams.concat(transformedQueryParams);
        }
        return fetchParams;
    };
    Page.prototype.transformPaginationParamsToFetchParams = function (queryParams) {
        var transformedParams = [];
        if (this.isDynamicPagination(queryParams)) {
            transformedParams = this.buildDynamicPaginationParams(queryParams);
        }
        else if (this.isStaticPagination(queryParams)) {
            transformedParams = this.buildStaticPaginationParams(queryParams);
        }
        return transformedParams;
    };
    Page.prototype.buildDynamicPaginationParams = function (queryParams) {
        var paginationParams = [];
        paginationParams.push({ haupia_rows: queryParams.rows.toString() });
        paginationParams.push({ haupia_start: queryParams.start.toString() });
        return paginationParams;
    };
    Page.prototype.buildStaticPaginationParams = function (queryParams) {
        var paginationParams = [];
        paginationParams.push({ haupia_pageNumber: queryParams.pageNumber.toString() });
        paginationParams.push({ haupia_pageSize: queryParams.pageSize.toString() });
        return paginationParams;
    };
    Page.prototype.isDynamicPagination = function (params) {
        return params.rows !== undefined;
    };
    Page.prototype.isStaticPagination = function (params) {
        return params.pageNumber !== undefined;
    };
    return Page;
}());
export { Page };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Bacon from "baconjs";
/** @hidden */
var DEFAULT_THRESHOLD = 0;
/** @hidden */
var SPECIAL_KEYS = ["Enter", "ArrowUp", "ArrowDown"];
/**
 *  This event is triggered the moment the box is displayed.
 */
var appearedEvent = new CustomEvent("hp-widget-appeared", {
    bubbles: true,
    cancelable: true,
});
/**
 *  This event is triggered the moment the box is hidden.
 */
var hiddenEvent = new CustomEvent("hp-widget-hidden", {
    bubbles: true,
    cancelable: true,
});
/**
 * The handler listening to this event will clear and hide the autocomplete widget.
 */
var inputEmpty = new CustomEvent("hp-search-input-empty", {
    bubbles: true,
    cancelable: true,
});
/**
 * UI-Widget that can be attached to an input element. Fetches and displays autocomplete suggestions.
 *
 * If you want to render your own HTML and just fetch the autocomplete suggestions, use [[haupia.fetchAutocompleteList]]
 * instead.
 */
var AutocompleteWidget = /** @class */ (function () {
    /**
     * @param autocompleteClient The [[FetchClient]] used to send API calls against the backend.
     * @param options The options used to modify the autocomplete function
     */
    function AutocompleteWidget(autocompleteClient, options) {
        this.autocompleteClient = autocompleteClient;
        this.options = options;
    }
    /**
     * A function meant to be called at every keystroke that returns a list of suggestions for autocompletion.
     *
     * @param prefix All characters typed so far, all strings returned by this function will start with this prefix.
     * @returns The highlighted or default autocomplete list for the given prefix, contains at most 5 elements.
     */
    AutocompleteWidget.prototype.getAutocompleteList = function (prefix, options) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var params, autocompleteList, error_1;
            var _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!options) {
                            //janky hack to work around a typedoc problem with default parameter values
                            options = this.options;
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        params = [
                            {
                                prefix: prefix.toLowerCase(),
                            }
                        ];
                        if ((_a = options) === null || _a === void 0 ? void 0 : _a.language) {
                            params.push({ language: options.language });
                        }
                        this.autocompleteClient.parameterList = (_c = this.autocompleteClient.parameterList).addOrUpdate.apply(_c, params);
                        return [4 /*yield*/, this.autocompleteClient.fetch()];
                    case 2:
                        autocompleteList = (_d.sent());
                        if ((_b = options) === null || _b === void 0 ? void 0 : _b.highlight) {
                            return [2 /*return*/, autocompleteList.map(function (item) { return _this.highlightPrefix(item, prefix); })];
                        }
                        return [2 /*return*/, autocompleteList];
                    case 3:
                        error_1 = _d.sent();
                        throw new Error(error_1);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Highlights the prefix (typed by the user in the input field) using the html strong tag.
     *
     * @param item An item from the list of autocomplete suggestions.
     * @param prefix The prefix to highlight.
     */
    AutocompleteWidget.prototype.highlightPrefix = function (item, prefix) {
        var suffix = item.substr(item.indexOf(prefix) + prefix.length);
        return "<strong>" + prefix + "</strong>" + suffix;
    };
    AutocompleteWidget.prototype.showBox = function (box) {
        if (box.style.visibility == "hidden") {
            box.style.visibility = "visible";
            box.dispatchEvent(appearedEvent);
        }
    };
    AutocompleteWidget.prototype.hideBox = function (box) {
        box.style.visibility = "hidden";
        box.dispatchEvent(hiddenEvent);
    };
    AutocompleteWidget.prototype.clearAutocompleteBox = function (box) {
        this.hideBox(box);
        box.querySelectorAll(".hp-autocomplete-item").forEach(function (item) { return item.remove(); });
    };
    AutocompleteWidget.prototype.createAutocompleteBox = function (inputId) {
        var box = document.createElement("div");
        box.setAttribute("id", inputId + " hp-autocomplete-list");
        box.setAttribute("class", "hp-autocomplete-widget");
        return box;
    };
    /**
     * Removes highlights from a highlighted item.
     *
     * @param item A string containing a &lt;strong&gt;-tag.
     */
    AutocompleteWidget.prototype.removeHighlights = function (item) {
        var temp = document.createElement("div");
        temp.innerHTML = item;
        return temp.textContent || "";
    };
    // TODO: The CSS-classes should be configurable.
    /**
     * Marks the active item with the 'hp-autocomplete-active' css class.
     *
     * @param autocompleteList The html element containing all the autocomplete suggestions
     * @param cursorIndex TODO
     */
    AutocompleteWidget.prototype.markActiveItem = function (autocompleteList, cursorIndex) {
        var acItems = Array.from(autocompleteList.querySelectorAll(".hp-autocomplete-item"));
        var markedItem = acItems
            .filter(function (item, index) { return index === cursorIndex; })
            .map(function (item) {
            item.classList.add("hp-autocomplete-active");
            return item;
        })
            .pop();
        acItems
            .filter(function (item, index) { return index !== cursorIndex; })
            .forEach(function (item) { return item.classList.remove("hp-autocomplete-active"); });
        return markedItem;
    };
    AutocompleteWidget.prototype.renderItem = function (acItem) {
        var renderedItem = document.createElement("div");
        renderedItem.setAttribute("class", "hp-autocomplete-item");
        renderedItem.innerHTML = acItem;
        return renderedItem;
    };
    /**
     * Creates the widget and attaches it to the given HTMLInputElement.
     *
     * @param inputElement The HTML input element to attach the widget to.
     */
    AutocompleteWidget.prototype.initializeAndAttach = function (inputElement, renderTarget) {
        var _this = this;
        var box = this.createAutocompleteBox(inputElement.id);
        if (renderTarget) {
            renderTarget.appendChild(box);
        }
        else if (inputElement.parentNode) {
            inputElement.parentNode.appendChild(box);
        }
        else {
            throw new Error("Given input element has no parent. Please attach it to an element in the DOM tree.");
        }
        this.hideBox(box);
        //handles the typing
        Bacon.fromEvent(inputElement, "keydown")
            .debounce(300)
            .filter(function (event) { return !SPECIAL_KEYS.includes(event.key); })
            .map(function (event) {
            var target = event.target;
            return target.value;
        })
            .skipDuplicates()
            .flatMapLatest(function (prefix) {
            var _a;
            inputElement.addEventListener("hp-search-input-empty", function () { return _this.clearAutocompleteBox(box); }, {
                once: true,
            });
            var threshold = ((_a = _this.options) === null || _a === void 0 ? void 0 : _a.prefixThreshold) || DEFAULT_THRESHOLD;
            if (prefix.length === 0) {
                dispatchEvent(inputEmpty);
            }
            else if (prefix.length > threshold) {
                return Bacon.fromPromise(_this.getAutocompleteList(prefix));
            }
            return [];
        })
            .onValue(function (list) {
            _this.clearAutocompleteBox(box);
            list.forEach(function (item) {
                _this.showBox(box);
                var renderedItem = _this.renderItem(item);
                renderedItem.addEventListener("click", function () {
                    inputElement.value = _this.removeHighlights(item);
                    inputElement.focus();
                    _this.clearAutocompleteBox(box);
                });
                box.appendChild(renderedItem);
            });
        });
        //handles the cursor moved by the arrow keys
        var up = Bacon.fromEvent(inputElement, "keydown").filter(function (event) { return event.key === "ArrowUp"; });
        var down = Bacon.fromEvent(inputElement, "keydown").filter(function (event) { return event.key === "ArrowDown"; });
        var enter = Bacon.fromEvent(inputElement, "keydown").filter(function (event) { return event.key === "Enter"; });
        var reset = Bacon.fromEvent(box, "hp-widget-appeared");
        Bacon.update(-1, [down, function (prev) { return Math.min(prev + 1, 4); }], //the maximum amount of items in the list we get from haupia is 5
        [up, function (prev) { return Math.max(prev - 1, -1); }], //-1 because pressing down for the first time will move the cursor from the input field to the first item
        [reset, function () { return -1; }], [enter, function () { return -1; }]).onValue(function (highlightIndex) {
            var highlightedItem = _this.markActiveItem(box, highlightIndex);
            inputElement.addEventListener("keydown", function (event) {
                var _a;
                if (event.key === "Enter") {
                    (_a = highlightedItem) === null || _a === void 0 ? void 0 : _a.click();
                }
            });
        });
    };
    return AutocompleteWidget;
}());
export { AutocompleteWidget };

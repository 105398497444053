/**
 * Represents a single haupia search result.
 */
var SearchResult = /** @class */ (function () {
    function SearchResult(searchResult, highlights) {
        this.result = searchResult;
        this.highlights = highlights[searchResult.id];
    }
    /**
     * Renders the search result using the passed function.
     *
     * @param templateFunction
     */
    SearchResult.prototype.render = function (templateFunction) {
        return templateFunction(this.result, this.highlights);
    };
    return SearchResult;
}());
export { SearchResult };

import { List } from "immutable";
/**
 * This class represents the URL parameters used within each request sent to the haupia backend.
 */
var URLParamList = /** @class */ (function () {
    function URLParamList(params) {
        this._list = List([]).concat(params || []);
    }
    Object.defineProperty(URLParamList.prototype, "list", {
        /**
         * Contains the parameters that are going to be referenced in the methods down below.
         */
        get: function () {
            return this._list;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Adds the given parameters or updates them if they already exist.
     *
     * If there are multiple parameters with the same key, only the first parameter is updated. If you want to update
     * multiple parameters with the same key use the [[deleteAll]] function first then add the new parameters using
     * [[add]]
     *
     * @param parameters The parameters to add or to update.
     *
     * @returns A new list with the new or modified parameters.
     */
    URLParamList.prototype.addOrUpdate = function () {
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        var updatedList = new URLParamList(this.list);
        for (var _a = 0, parameters_1 = parameters; _a < parameters_1.length; _a++) {
            var parameter = parameters_1[_a];
            var key = this.convert(parameter)[0];
            var index = updatedList.getIndexForKey(key);
            if (index != -1) {
                updatedList = new URLParamList(updatedList.list.set(index, parameter));
            }
            else {
                updatedList = updatedList.add(parameter);
            }
        }
        return updatedList;
    };
    /**
     * Adds a search term as parameter (with the key *query*) or updates it if a search term already exists.
     *
     * @param searchTerm The search term to add or update.
     *
     * @returns A new list with the new or modified search term. If the search term is empty, a copy of the original
     * list will be returned.
     */
    URLParamList.prototype.addOrUpdateSearchTerm = function (searchTerm) {
        var list = this.list;
        if (!searchTerm) {
            return new URLParamList(list);
        }
        return this.addOrUpdate({ query: searchTerm });
    };
    /**
     * Adds all given parameters.
     *
     * @param parameters The parameters to add.
     *
     * @returns A new list with the new parameters.
     */
    URLParamList.prototype.add = function () {
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        var list = this.list.concat(parameters);
        return new URLParamList(list);
    };
    /**
     * Converts a single [[URLParam]] into a string array of length 2, with key at position 0 and value at position 1.
     *
     * @param parameter The parameter to convert.
     */
    URLParamList.prototype.convert = function (parameter) {
        var key = Object.keys(parameter)[0];
        var value = parameter[key];
        return [key, value];
    };
    /**
     * Returns the first parameter matching the given key.
     *
     * @param key The key of the searched parameter.
     */
    URLParamList.prototype.getOne = function (key) {
        return this.list.find(function (parameter) { return Object.keys(parameter)[0] === key; }) || null;
    };
    /**
     * Returns all parameters matching the given key.
     *
     * @param key The key of the searched parameters.
     */
    URLParamList.prototype.getMany = function (key) {
        var _this = this;
        return this.list.filter(function (parameter) {
            var parameterKey = _this.convert(parameter)[0];
            return key === parameterKey;
        });
    };
    /**
     * Deletes all parameters matching the given key.
     *
     * @param key The key of the parameters to be deleted.
     *
     * @returns A new list without the deleted parameters.
     */
    URLParamList.prototype.deleteAll = function (key) {
        return new URLParamList(this.list.filter(function (parameter) { return Object.keys(parameter)[0] !== key; }));
    };
    /**
     * Deletes all parameters matching both keys and values.
     *
     * @param parameters The parameters to be deleted.
     *
     * @return A new list without the deleted parameters.
     */
    URLParamList.prototype.delete = function () {
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        var updatedList = new URLParamList(this.list);
        parameters.forEach(function (parameter) {
            var index = updatedList.getIndexForParameter(parameter);
            updatedList = new URLParamList(updatedList.list.delete(index));
        });
        return updatedList;
    };
    /**
     * Converts the stored parameters into a string that can be appended to a URL.
     */
    URLParamList.prototype.toString = function () {
        var _this = this;
        var searchParams = new URLSearchParams();
        this.list.forEach(function (parameter) {
            var key = _this.convert(parameter)[0];
            searchParams.append(key, parameter[key]);
        });
        return searchParams.toString();
    };
    URLParamList.prototype.toArray = function () {
        return this.list.toArray();
    };
    /**
     * Updates the first parameter matching the key if it exists.
     *
     * Use this only if you know your parameter is unique. For updating all parameters with the same key, delete them
     * first using [[deleteAll]] and then add the new ones.
     *
     * @param parameter The parameter to update.
     *
     * @returns A new list with the updated parameter. If the parameter's key doesn't exist, a copy of the original list
     * will be returned.
     */
    URLParamList.prototype.updateFirst = function (parameter) {
        var list = this.list;
        var key = this.convert(parameter)[0];
        var index = this.getIndexForKey(key);
        if (index != -1) {
            return new URLParamList(list.set(index, parameter));
        }
        return new URLParamList(list);
    };
    /**
     * Updates a specific parameter matching both key and value with the given new parameter.
     *
     * @param oldParameter The parameter to update.
     * @param newParameter The new parameter to update to.
     *
     * @returns A new list with the updated parameter.
     */
    URLParamList.prototype.update = function (oldParameter, newParameter) {
        var list = this.list;
        var index = this.getIndexForParameter(oldParameter);
        return new URLParamList(list.set(index, newParameter));
    };
    /** @hidden */
    URLParamList.prototype.getIndexForKey = function (key) {
        return this.list.findIndex(function (listParam) {
            var listParamKey = Object.keys(listParam)[0];
            return listParamKey === key;
        });
    };
    /** @hidden */
    URLParamList.prototype.getIndexForParameter = function (parameter) {
        var _this = this;
        return this.list.findIndex(function (listParam) {
            var _a = _this.convert(listParam), listParamKey = _a[0], listParamValue = _a[1];
            var _b = _this.convert(parameter), key = _b[0], value = _b[1];
            return listParamKey === key && listParamValue === value;
        });
    };
    return URLParamList;
}());
export { URLParamList };

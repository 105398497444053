import { CSS_PAGINATION, CSS_NEXT_BUTTON, CSS_PREV_BUTTON, CSS_PAGE_BUTTON, CSS_CURRENT_PAGE, PAGINATION_FUNCTION, } from "../../defaults";
/**
 * Represents the users desired shape of the pagination. If you want to change the appearance of the pagination buttons
 * you can write and use your custom render function. The default appearance would be
 * ```
 * prev 1 2 3 4 5 next
 * ```
 * If you wanted your pagination to instead look like this
 * ```
 * < 1 2 3 4 5 >
 * ```
 * You would have to write custom renderNext and renderPrev functions like this
 * ```
 * page.paginationTemplate.renderNext = (value) => {
 *      return `>`
 * }
 * page.paginationTemplate.renderPrev = (value) => {
 *      return `<`
 * }
 * ```
 * Whatever gets returned by your function will be wrapped in a \<li> tag
 */
var PaginationTemplate = /** @class */ (function () {
    /**
     * @param renderPaginationFunction The function to render a single pagination element, eg. the previous button.
     * @param cssClasses The CSS classes that are attached to the elements.
     */
    function PaginationTemplate() {
        this.cssClasses = this.initDefaultClasses();
        this.renderNext = PAGINATION_FUNCTION;
        this.renderPage = PAGINATION_FUNCTION;
        this.renderPrev = PAGINATION_FUNCTION;
    }
    PaginationTemplate.prototype.initDefaultClasses = function () {
        return {
            pagination: [CSS_PAGINATION],
            nextButton: [CSS_NEXT_BUTTON],
            prevButton: [CSS_PREV_BUTTON],
            pageButton: [CSS_PAGE_BUTTON],
            currentPage: [CSS_CURRENT_PAGE],
        };
    };
    return PaginationTemplate;
}());
export { PaginationTemplate };
